import React from "react";
import AgreedHomeComponent from "../component/OurWork/agreedHomeComponent";
import ChallengeComponent from '../component/OurWork/challengeComponent';
import SolutionsComponent from "../component/OurWork/solutionsComponent";
import Footer from '../component/footerComponent';
import QuoteComponent from "../component/OurWork/quoteComponent";
import ResultsComponent from "../component/OurWork/resultsComponent";
import { graphql } from 'gatsby';
import Layout from "../component/layout";
const  PurposeFinder = (props) =>{
    const{
        data:{
          wpPage:{
         
            projectChallengeSection :{
                projectChallengeDesignDescription,
                projectChallengeDesignTitle,
                projectChallengeDevelopmentDescription,
                projectChallengeDevelopmentTitle,
                projectChallengeFeatureDescription,
                projectChallengeFeatureTitle,
                projectChallengeTitle,
                projectChallengeImage :{
                  localFile :{
                    childImageSharp :{
                      gatsbyImageData:projectChallengeImage
                    },
                  },
                },
              },
              projectQuoteSection :{
                projectQuoteDescription
              },
              projectResultsSection :{
                projectResultsDescription,
                projectResultsTitle,
                  projectResultsImageFifth: {
                    localFile :{
                      childImageSharp :{
                        gatsbyImageData:projectResultsImageFifth
                      },
                    },
                  },
                  projectResultsImageForth: {
                    localFile :{
                      childImageSharp :{
                        gatsbyImageData:projectResultsImageForth
                      },
                    },
                  },
                  // projectResultsImageSixth: {
                  //   localFile :{
                  //     childImageSharp :{
                  //       gatsbyImageData:projectResultsImageSixth
                  //     },
                  //   },
                  // },
                  // projectSectionImageSeventh: {
                  //   localFile :{
                  //     childImageSharp :{
                  //       gatsbyImageData:projectSectionImageSeventh
                  //     },
                  //   },
                  // },
                projectResultsImage :{
                  localFile :{
                    childImageSharp :{
                      gatsbyImageData:projectResultsImage
                    },
                  },
                },
                projectResultsImageSecond :{
                  localFile :{
                    childImageSharp :{
                      gatsbyImageData:projectResultsImageSecond
                    },
                  },
                },
                projectResultsImageThird :{
                  localFile :{
                    childImageSharp :{
                      gatsbyImageData:projectResultsImageThird
                    },
                  },
                },
              },
              projectSolutionsSection :{
                projectSolutionsNewFeaturesDescription,
                projectSolutionsNewFeaturesTitle,
                projectSolutionsTitle,
                projectSolutionsUserExperienceDescription,
                projectSolutionsUserExperienceTitle,
                projectSolutionsWebApplicationDescription,
                projectSolutionsWebApplicationTitle,
              },
              agreedHomeSection :{
                agreedHomeData,
                agreedHomeDescription,
                agreedHomeLocation,
                agreedHomeTitle,
                agreedHomeImage :{
                  localFile :{
                    childImageSharp :{
                      gatsbyImageData:agreedHomeImage
                    },
                  },
                },
              },
            },
        },
    } = props;
    return(
        < >
        <AgreedHomeComponent
        agreedHomeTitle={agreedHomeTitle}
        agreedCalendarText={agreedHomeData}
        agreedLocationText={agreedHomeLocation}
        agreedHomeDescription={agreedHomeDescription}
        projectImage={agreedHomeImage}
        />
        <ChallengeComponent
        challengeDescription={projectChallengeDevelopmentDescription}
        challengeText={projectChallengeFeatureDescription}
        challengeTitle={projectChallengeTitle}
        challengeInneficient={projectChallengeDevelopmentTitle}
        challengeComplicated={projectChallengeDesignTitle}
        challengeNecessary={projectChallengeFeatureTitle}
        challengeComplicatedText={projectChallengeDesignDescription}
        projectChallengeImage={projectChallengeImage}
        />
        <SolutionsComponent
        solutionsTitle={projectSolutionsTitle}
        userExperienceTitle={projectSolutionsUserExperienceTitle}
        userExperienceDescription={projectSolutionsUserExperienceDescription}
        webApplicationTitle={projectSolutionsWebApplicationTitle}
        webApplicationDescription={projectSolutionsWebApplicationDescription}
        addFeaturesTitle={projectSolutionsNewFeaturesTitle}
        addFeaturesDescription={projectSolutionsNewFeaturesDescription}
        isWorkPage={true}
        isProject={true}
        />
        <QuoteComponent
        quoteText={projectQuoteDescription}
        />
        <ResultsComponent
        resultsTitle={projectResultsTitle}
        resultsDescription={projectResultsDescription}
        projectFirstImage={projectResultsImage}
        projectSecondImage={projectResultsImageSecond}
        projectThirdImage={projectResultsImageThird}
        projectFifthImage={projectResultsImageFifth}
        projectForthImage={projectResultsImageForth}
        // projectSixthImage={projectResultsImageSixth}
        // projectSeventhImage={projectSectionImageSeventh}
        // isAgreed={true}
        isOtherProject={true}
        />
        <Footer/>
        </>
    )
}
export const query = graphql`
  query {
    wpPage(slug: {eq: "purpose-finder-page"}){
      projectChallengeSection {
          projectChallengeDesignDescription
          projectChallengeDesignTitle
          projectChallengeDevelopmentDescription
          projectChallengeDevelopmentTitle
          projectChallengeFeatureDescription
          projectChallengeFeatureTitle
          projectChallengeTitle
          projectChallengeImage {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        projectQuoteSection {
          projectQuoteDescription
        }
        projectResultsSection {
          projectResultsDescription
          projectResultsTitle
            projectResultsImageFifth {
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: BLURRED)
                }
              }
            }
            projectResultsImageForth {
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: BLURRED)
                }
              }
            }
            projectResultsImageSixth {
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: BLURRED)
                }
              }
            }
            projectSectionImageSeventh {
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: BLURRED)
                }
              }
            }
          projectResultsImage {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
          projectResultsImageSecond {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
          projectResultsImageThird {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
        }
        projectSolutionsSection {
          projectSolutionsNewFeaturesDescription
          projectSolutionsNewFeaturesTitle
          projectSolutionsNextLevelDescription
          projectSolutionsNextLevelTitle
          projectSolutionsTitle
          projectSolutionsUserExperienceDescription
          projectSolutionsUserExperienceTitle
          projectSolutionsWebApplicationDescription
          projectSolutionsWebApplicationTitle
        }
        agreedHomeSection {
          agreedHomeData
          agreedHomeDescription
          agreedHomeLocation
          agreedHomeTitle
          agreedHomeImage {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
        }
}
  }
  `;
export default PurposeFinder